import React from 'react';
import './App.css';

type Arrival = {
  hour: number,
  minute: number,
  suspended: boolean,
  line_index: number,
};

type Line = {
  name: string,
  color: string,
};

function isFutureArrival(arrival: Arrival, date: Date) {
  if (arrival.hour > date.getHours())
    return true;
  return arrival.hour === date.getHours() && arrival.minute >= date.getMinutes();
}

function getFirstArrivalAfterDate(arrivals: Arrival[], date: Date) {
  for (const arrival of arrivals) {
    if (isFutureArrival(arrival, date)) {
      return arrival;
    }
  }
  return null;
}

type TimeTableProps = {
  arrivals: Arrival[],
  lines: Line[],
  currentDate: Date,
  filterPast: boolean,
  onShowPast: () => void,
  direction: string,
  dayOfWeekMatched: boolean
};

function TimeTable(props: TimeTableProps) {
  let arrivals = props.arrivals;
  if (props.filterPast) {
    arrivals = arrivals.filter((arrival) => isFutureArrival(arrival, props.currentDate));
  }
  let arrivals_by_hour: {
    hour: number,
    arrivals: Arrival[],
  }[] = [];
  arrivals.forEach((arrival) => {
    if (arrivals_by_hour.length === 0 || arrivals_by_hour[arrivals_by_hour.length - 1].hour !== arrival.hour) {
      arrivals_by_hour.push({ hour: arrival.hour, arrivals: [arrival] });
    } else {
      arrivals_by_hour[arrivals_by_hour.length - 1].arrivals.push(arrival);
    }
  });
  return (
    <div className="time-table">
      <table><tbody>
        <tr key={-2}>
          <td colSpan={2} className="departing-station">{props.direction}</td>
        </tr>
        {(props.filterPast && props.arrivals.length !== arrivals.length) &&
          <tr key={-1}>
            <td colSpan={2} className="past-buses"><button onClick={props.onShowPast}>過去のバス...</button></td>
          </tr>
        }
        {arrivals_by_hour.map((arrivals_for_hour, i) => {
          return (
            <tr key={i}>
              <td className={"hour" + (props.dayOfWeekMatched && arrivals_for_hour.hour < props.currentDate.getHours() ? " past-hour" : "")}>{arrivals_for_hour.hour}</td>
              <td className="arrivals">{arrivals_for_hour.arrivals.map((arrival, i) => {
                return (
                  <span key={i} style={{borderBottom: '2px solid ' + props.lines[arrival.line_index].color}} className={props.dayOfWeekMatched && !isFutureArrival(arrival, props.currentDate) ? 'past-bus' : ''}>{arrival.minute}</span>
                );
              })}</td>
            </tr>
          );
        })}
      </tbody></table>
    </div>
  );
}

type AppState = {
  direction: string,
  day_of_week: string,
  current_date: Date,
  filter_past: boolean,
};

function yyyymmdd(date: Date) {
  return (new Date(date.getTime() - (date.getTimezoneOffset()*60*1000))).toISOString().split('T')[0];
}

function latlngd(lat1: number, lon1: number, lat2: number, lon2: number) {
  // From https://www.movable-type.co.uk/scripts/latlong.html
  const R = 6371e3; // metres
  const φ1 = lat1 * Math.PI/180; // φ, λ in radians
  const φ2 = lat2 * Math.PI/180;
  const Δφ = (lat2-lat1) * Math.PI/180;
  const Δλ = (lon2-lon1) * Math.PI/180;

  const a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
            Math.cos(φ1) * Math.cos(φ2) *
            Math.sin(Δλ/2) * Math.sin(Δλ/2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

  return R * c; // in metres
}

class App extends React.Component<{ data: any, holidays: { [date: string]: string } }, AppState> {
  timer_id: number;

  constructor(props: any) {
    super(props);
    const date = new Date()
    this.state = { direction: "上り", day_of_week: (this.isHolidayOrWeekend(date) ? "土休日" : "平日"), current_date: date, filter_past: true };
    this.timer_id = -1;

    this.handleSwitchChange = this.handleSwitchChange.bind(this);
    this.handleShowPast = this.handleShowPast.bind(this);
  }

  componentDidMount() {
    this.timer_id = window.setInterval(
      () => this.setState( { current_date: new Date() } ),
      1000);

    const kaneda = {lat: 35.432041, lon: 139.920728}
    const tokyo = {lat: 35.679488, lon: 139.768639}
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const current = position.coords;
        const distKaneda = latlngd(kaneda.lat, kaneda.lon, current.latitude, current.longitude);
        const distTokyo = latlngd(tokyo.lat, tokyo.lon, current.latitude, current.longitude);
        // console.log('distance to tokyo: ' + distTokyo + ' distance to kaneda: ' + distKaneda);
        if (distKaneda < distTokyo) {
          this.setState({ direction: "上り" });
        } else {
          this.setState({ direction: "下り" });
        }
      });
    }
  }

  componentWillMount() {
    clearInterval(this.timer_id);
  }

  handleSwitchChange(event: React.ChangeEvent<HTMLInputElement>) {
    let element = event.target as HTMLInputElement;
    let newState: any = { filter_past: true };
    newState[element.name] = element.value;
    this.setState(newState);
  }

  handleShowPast() {
    this.setState({ filter_past: false });
  }

  isHolidayOrWeekend(date: Date) {
    if (yyyymmdd(date) in this.props.holidays) {
      return true;
    }
    const day_index = date.getDay();
    return day_index === 0 || day_index === 6;
  }

  render() {
    const lines = this.props.data.lines as Line[];
    let arrivals = this.props.data.time_tables[this.state.direction][this.state.day_of_week] as Arrival[];
    arrivals = arrivals.filter((arrival) => !arrival.suspended)
    const first_arrival = getFirstArrivalAfterDate(arrivals, this.state.current_date);
    let eta = null;
    if (first_arrival) {
      eta = (first_arrival.hour - this.state.current_date.getHours()) * 60 +
            (first_arrival.minute - this.state.current_date.getMinutes());
    }
    const day_of_week_matched = (this.state.day_of_week === '土休日') === this.isHolidayOrWeekend(this.state.current_date);
    return (
      <div className="App">
        <header>
          <h1>木更津金田バスターミナル時刻表</h1>
        </header>
        {day_of_week_matched && first_arrival &&
          <div className="next-bus">
            <p className="label">
              次発のバス:
            </p>
            <p className="time">
              <span className="hhmm">{first_arrival.hour}:{('0' + first_arrival.minute).slice(-2)}</span>
              <span className="eta">(約{eta}分)</span>
            </p>
          </div>
        }
        <div className="chooser">
          <p className="direction switch">
            <input type="radio" name="direction" id="direction_up" value="上り" checked={this.state.direction === '上り'} onChange={this.handleSwitchChange} />
            <label htmlFor="direction_up">上り</label>
            <input type="radio" name="direction" id="direction_down" value="下り" checked={this.state.direction === '下り'} onChange={this.handleSwitchChange} />
            <label htmlFor="direction_down">下り</label>
          </p>
          <p className="day-of-week switch">
            <input type="radio" name="day_of_week" id="day_of_week_weekday" value="平日" checked={this.state.day_of_week === '平日'} onChange={this.handleSwitchChange} />
            <label htmlFor="day_of_week_weekday">平日</label>
            <input type="radio" name="day_of_week" id="day_of_week_holiday" value="土休日" checked={this.state.day_of_week === '土休日'} onChange={this.handleSwitchChange} />
            <label htmlFor="day_of_week_holiday">土休日</label>
          </p>
        </div>
        <TimeTable
          arrivals={arrivals}
          lines={lines}
          currentDate={this.state.current_date}
          filterPast={this.state.filter_past && !!first_arrival && day_of_week_matched}
          onShowPast={this.handleShowPast}
          direction={this.state.direction === '上り' ? '木更津金田バスターミナル発' : '東京駅八重洲口前発' }
          dayOfWeekMatched={day_of_week_matched}
          />
        <div className="lines">
          {lines.map((line, i) => {
            return <p key={i}><span style={{color: line.color }}>■</span>{line.name}</p>;
          })}
        </div>
      </div>
    );
  }
}

export default App;
